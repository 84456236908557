import * as React from 'react';

export default function Brewing(){
    return(
        <>
            <h1>
                Brewing
            </h1>
        </>
    )
}